<template>
	<div>
		<b-row>
			<b-col cols="12" class="mb-2">
				<h3 class="mb-0 text-center titulo-tab">
					Documentos Necessários
				</h3>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cedente (quem está vendendo):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples)</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjugue do Cedente (em caso de Cedente Casado):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples).</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de residência atualizado.</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjugue do Cedente (em caso de Cedente Casado):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cessionário (quem está Comprando):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples).</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de residência atualizado.</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Profissão.</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjuge do Cessionário (em caso de Cessionário casado):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Profissão.</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Caso o contrato esteja no nome de Pessoa Jurídica, enviar também:
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Contrato Social</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Última Alteração Contratual</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Cartão CNPJ e QSA</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão Simplificada</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de Endereço atualizado</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CND de Condomínio</p>
				</div>

			</b-col>
		</b-row>

	</div>
</template>
<style scoped>
.requisito {
	margin: 0;
	width: 90%;
}

.titulo-tab {
	margin-bottom: 50px!important;
}
</style>

<script>
import {
	BRow,
	BCol,
} from 'bootstrap-vue'

export default {
	name: 'TabDocumentos',
	components: {
		BRow,
		BCol,
	},
}
</script>
