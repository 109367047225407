<template>
   <div>
      <b-row>
         <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-left">
               Representates Legais
            </h5>
         </b-col>
      </b-row>

      <b-row v-for="(representante, index) in represetantesLegais" :key="index">

         <b-col v-if="index > 0" md="12 direita">
            <b-button @click="removerLinha(index)" variant="danger" class="mr-1" style="padding: 5px">
               Remover <feather-icon size="15" icon="XIcon" />
            </b-button>
         </b-col>

         <b-col v-if="cessionarios.length > 1" md="12">
            <b-form-group label="Cessionario Representado" label-for="cessionarioRepresentante" invalid-feedback="Selecione uma Cessionario." :state="representante.cessionario != null">
               <v-select id="cessionarioRepresentante" :clearable="false" v-model="representante.cessionario"
                  :options="cessionarios" @input="representante.cessionario"
                  :getOptionLabel="customerContracts => customerContracts.nome" :searchable="true" :state="representante.cessionario != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Nome do Representante" label-for="nomeRepresentante" invalid-feedback="Entre com o nome completo." :state="representante.nome.length > 5">
               <b-form-input id="nomeRepresentante" type="text" v-model="representante.nome" :state="representante.nome.length > 5" placeholder="Exemplo" />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="CPF do Representante" label-for="cpfRepresentante" invalid-feedback="CPF Incorreto." :state="validacaoCpf(representante.cpf)">
               <b-form-input id="cpfRepresentante" v-mask="maskCpf" type="text" v-model="representante.cpf"
                  placeholder="000.000.000.00" :state="validacaoCpf(representante.cpf)" trim />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Email do Representante" label-for="emailRepresentante" invalid-feedback="E-mail Invalido." :state="validacaoEmail(representante.email)">
               <b-form-input id="emailRepresentante" type="email" v-model="representante.email"
                  placeholder="Exemplo.ex@email.com" :state="validacaoEmail(representante.email)" />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Principal" label-for="telefoneRepresentante" invalid-feedback="Telefone Invalido."
            :state="validacaoTelefone(representante.telefonePrincipal)">
               <b-form-input id="telefoneRepresentante" v-mask="mask" v-model="representante.telefonePrincipal"
                  placeholder="(99) 99999-9999" :state="validacaoTelefone(representante.telefonePrincipal)" />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Recado" label-for="telefone2Representante" invalid-feedback="Telefone Invalido."
            :state="validacaoTelefone(representante.telefoneRecado)">
               <b-form-input id="telefone2Representante" v-mask="mask" v-model="representante.telefoneRecado"
                  placeholder="(99) 99999-9999" :state="validacaoTelefone(representante.telefoneRecado)" />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Estado Civil" label-for="estadoCivilRepresentante" invalid-feedback="Selecione um Estado Civil." :state="representante.estadoCivil != null">
               <v-select id="estadoCivilRepresentante" :clearable="false" v-model="representante.estadoCivil"
                  :options="optionsEstadoCivil" @input="representante.estadoCivil"
                  :getOptionLabel="customerContracts => customerContracts.text" :searchable="true" :state="representante.estadoCivil != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Profissão" label-for="profissaoRepresentante" invalid-feedback="Selecione uma Profissão." :state="representante.profissao != null">
               <v-select id="profissaoRepresentante" :clearable="false" v-model="representante.profissao"
                  :options="opcoesProfissao" @input="representante.profissao"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true" :state="representante.profissao != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Naturalidade" label-for="naturalidadeRepresentante" invalid-feedback="Selecione uma Profissão." :state="representante.naturalidade != null">
               <v-select id="naturalidadeRepresentante" :clearable="false" v-model="representante.naturalidade"
                  :options="opcoesNaturalidade" @input="representante.naturalidade"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true" :state="representante.naturalidade != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="12">
            <div class="divider"></div>
          </b-col>

      </b-row>

      <b-row>
         <b-col md="12 centro">
            <b-button @click="adicionarLinha" variant="primary" class="mr-1" style="padding: 5px">
               <feather-icon size="15" icon="PlusIcon" />
               Adicionar Representante
            </b-button>
         </b-col>
      </b-row>

   </div>
</template>

<style scoped>

.centro {
   display: flex;
   align-items: center;
   justify-content: center;
}

.direita {
   display: flex;
   align-items: right;
   justify-content: right;
}

.divider {
  background: #d0d2d6;
  width: 100%;
  height: 1px;
  margin-bottom: 2%;
  margin-top: 2%;
}

</style>

<script>
import vSelect from 'vue-select'
import {
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BButton,
} from 'bootstrap-vue'
import {
  validarCPFCNPJ,
  validarEmail,
  validarTelefone
} from '@/utils/validations.js'

export default {
   props: {
      cessionarios: {
         type: Array,
         required: true
      },
      represetantesLegais: {
         type: Array,
         required: true
      },
      opcoesNaturalidade: {
         type: Array,
         required: true
      },
      opcoesProfissao: {
         type: Array,
         required: true
      },
      optionsEstadoCivil: {
         type: Array,
         required: true
      }
   },
   name: 'Representates',
   components: {
      BButton,
      BRow,
      vSelect,
      BCol,
      BFormGroup,
      BFormInput,
   },

   data() {
      return {
         mask: ['(', /\d/, /\d/, ') ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
         maskCpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      }
   },
   methods: {
      adicionarLinha() {
         this.represetantesLegais.push({
            nome: "",
            cpf: "",
            email: "",
            telefonePrincipal: "",
            telefoneRecado: "",
            estadoCivil: null,
            profissao: null,
            naturalidade: null,
         })
      },
      removerLinha(posicao) {
         this.represetantesLegais.splice(posicao, 1)
      },
      validacaoCpf(cpf) {
         return validarCPFCNPJ(cpf)
      },
      validacaoEmail(email) {
         return validarEmail(email)
      },
      validacaoTelefone(telefone) {
         return validarTelefone(telefone)
      }
   },

   computed: {
   }
}
</script>
