<template>
   <div>
      <b-row>
         <b-col cols="12" class="mb-2">
            <h3 class="mb-0 text-center">
               Selecione seu contrato 📄
            </h3>
         </b-col>
         <b-col cols="12" class="mb-2">
         <v-select v-if="customerContracts.length > 0" id="contract" :clearable="false" v-model="selectedContract"
            :options="customerContracts" @input="setSelected"
            :getOptionLabel="customerContracts => customerContracts.text" :searchable="false">
            <template #open-indicator="{ attributes }">
               <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
            </template>
         </v-select>
         </b-col>
      </b-row>
      <div class="d-flex text-left my-1 align-items-center">
         <h6 class="requisito">- {{ getSelectedContract.empreendimento_nome }} - {{
            getSelectedContract.empreendimento_municipio
         }}, {{ getSelectedContract.empreendimento_uf }}</h6>
      </div>

      <div class="d-flex text-left my-1 align-items-center">
         <h6 class="requisito">- Quadra {{ getSelectedContract.quadra }}</h6>
      </div>

      <div class="d-flex text-left my-1 align-items-center">
         <h6 class="requisito">- Lote {{ getSelectedContract.lote }}</h6>
      </div>

      <div class="d-flex text-left my-1 align-items-center">
         <h6 class="requisito">- Area {{ getSelectedContract.lote_area }} M²</h6>
      </div>
   </div>

</template>

<script>
import vSelect from 'vue-select'
import store from '@/store'
import formatValue from '@/utils/formatValue'
import { getContracts } from '@/services/contract'
import { logout } from '@/services/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
   BRow,
   BCol,
} from 'bootstrap-vue'
export default {
   name: 'TabInicio',
   components: {
      vSelect,
      BRow,
      BCol,
   },
   mixins: [formatValue],
   data() {
      return {
         selectedContract: null,
         attributes: {
            ref: 'openIndicator',
            role: 'presentation',
            class: 'vs__open-indicator',
         },
      }
   },
   methods: {
      setSelected() {
         store.commit('contracts/setSelectedContract', this.selectedContract)
      },
   },

   async mounted() {
      let { data, errors } = await getContracts(this.$apolloProvider.defaultClient)
      let contracts = data ? data : []
      if (contracts.length > 0) {
         store.commit('contracts/setCustomerContracts', contracts)
         this.selectedContract = this.getSelectedContract
         this.setSelected()
      } else {
         logout(this.$apolloProvider.defaultClient)

         this.$toast({
            component: ToastificationContent,
            props: {
               title: 'Ops!',
               icon: 'XIcon',
               variant: 'danger',
               text: errors,
            },
         })
      }

      if (!this.selectedContract?.value) {
         this.selectedContract = this.getSelectedContract ? this.getSelectedContract : null
         this.setSelected()
      }
   },

   beforeUpdate() {
      if (this.isEmptyObject(this.getSelectedContract) && this.customerContracts[0]) {
         this.selectedContract = this.customerContracts[0] ? this.customerContracts[0] : null
         this.setSelected()
      }
   },

   computed: {
      customerContracts() {
         return store.getters['contracts/getCustomerContracts'].length > 0
            ? store.getters['contracts/getCustomerContracts']
            : []
      },

      getSelectedContract() {
         return store.getters['contracts/getSelectedContract']
            ? store.getters['contracts/getSelectedContract']
            : null
      },
   },
}
</script>
