<template>
  <div>

    <b-modal v-model="loading" title="Carregando ...">
      <b-container fluid>

        <div class="d-flex justify-content-center">
          <BSpinner />
        </div>

      </b-container>

      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>


    <form-wizard color="#7367F0" :title="null" :subtitle="null" layout="vertical" finish-button-text="Salvar"
      back-button-text="Voltar" next-button-text="Proximo" class="wizard-vertical steps-none mb-3">

      <!-- Inicio tab -->
      <tab-content title="Informações" icon="feather icon-info">
        <TabInicio />
      </tab-content>

      <tab-content title="Contrato" icon="feather icon-file-text" :before-change="validateContrato">
        <TabContrato />
      </tab-content>

      <tab-content title="Requisitos" icon="feather icon-check-square" :before-change="validateRequisitos">
        <TabRequisitos />
      </tab-content>

      <tab-content title="Documentos" icon="feather icon-folder">
        <TabDocumentos />
      </tab-content>

      <tab-content title="Cedente" icon="feather icon-edit" :before-change="validarCedentes">
        <Cedentes :compradores="compradores" :opcoesNaturalidade="opcoesNaturalidade" :opcoesProfissao="opcoesProfissao"
          :optionsEstadoCivil="optionsEstadoCivil" />
      </tab-content>

      <tab-content title="Cessionário" icon="feather icon-edit" :before-change="validateForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h3 class="mb-0 text-center">
              Cessionário
            </h3>
            <h4 class="mb-0 text-center">
              (Novo Proprietário)
            </h4>
          </b-col>

          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-left">
              Dados Cessionário
            </h5>
          </b-col>

          <b-col md="12">
            <b-form-group label="Tipo de Cessionario *" label-for="tipoComprador">
              <v-select id="tipoComprador" :clearable="false" v-model="tipoComprador" :options="optionsTipoComprador"
                :getOptionLabel="customerContracts => customerContracts.text">
                <template #open-indicator="{ attributes }">
                  <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <Cessionarios :cessionarios="cessionarios" :opcoesNaturalidade="opcoesNaturalidade"
          :opcoesProfissao="opcoesProfissao" :optionsEstadoCivil="optionsEstadoCivil" :tipoComprador="tipoComprador"
          :optionsTipoComprador="optionsTipoComprador" />

        <Representates v-if="tipoComprador.value === 'Cnpj'" :cessionarios="cessionarios"
          :represetantesLegais="representantes" :opcoesNaturalidade="opcoesNaturalidade"
          :opcoesProfissao="opcoesProfissao" :optionsEstadoCivil="optionsEstadoCivil" />

        <b-row>

          <b-col md="12">
            <b-form-group label="Observações da Transferência" label-for="observacao">
              <b-form-textarea id="observacao" v-model="observacao"
                placeholder="Escreva alguma observação que possa ser necessaria." />
            </b-form-group>
          </b-col>

        </b-row>

      </tab-content>

      <tab-content title="Solicitação" icon="feather icon-info">
        <TabSolicitacao :numeroSolicitacao="numeroSolicitacao" />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()"
            :style="props.fillButtonStyle">Voltar</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right"
            :style="props.fillButtonStyle">Próximo</wizard-button>
          <wizard-button v-else @click.native="finalizarProcesso" class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"> {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}</wizard-button>
        </div>
      </template>

    </form-wizard>
  </div>
</template>

<style>
.obrigatorio {
  color: red
}

.divider-compradores {
  background: #d0d2d6;
  width: 100%;
  height: 1px;
  margin-bottom: 2%;
  margin-top: 2%;
}

@media only screen and (max-width: 700px) {
  .wizard-vertical .wizard-navigation .wizard-nav {
    display: none;
  }
}
</style>

<script>
import vSelect from 'vue-select'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import TabInicio from './Tabs/TabInicio.vue'
import TabRequisitos from './Tabs/TabRequisitos.vue'
import TabDocumentos from './Tabs/TabDocumentos.vue'
import TabSolicitacao from './Tabs/TabSolicitacao.vue'
import TabContrato from './Tabs/TabContrato.vue'
import Representates from './Components/Representantes.vue'
import Cedentes from './Components/Cedentes.vue'
import Cessionarios from './Components/Cessionarios.vue'
import store from '@/store'
import services from '@/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  validarCPFCNPJ,
  validarEmail,
  validarTelefone
} from '@/utils/validations.js'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'


export default {
  name: 'FormCessaoDireitos',
  components: {
    vSelect,
    BModal,
    WizardButton,
    FormWizard,
    TabContent,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    TabInicio,
    TabRequisitos,
    TabDocumentos,
    TabSolicitacao,
    TabContrato,
    Representates,
    Cedentes,
    Cessionarios
  },
  data() {
    return {
      mask: ['(', /\d/, /\d/, ') ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      maskCpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      nomeCessionario: "",
      cpfCessionario: "",
      telefoneCessionario: "",
      telefone2Cessionario: "",
      estadoCivilCessionario: null,
      profissaoCessionario: null,
      naturalidadeCessionario: null,
      nomeConjuge: "",
      cpfConjuge: "",
      emailCessionario: "",
      telefoneConjuge: "",
      telefone2Conjuge: "",
      emailConjuge: "",
      estadoCivilConjuge: null,
      profissaoConjuge: null,
      naturalidadeConjuge: null,
      observacao: "",
      numeroSolicitacao: '0',
      loading: false,
      tipoComprador: {
        value: 'Cpf', text: 'Pessoa Física'
      },
      optionsTipoComprador: [
        { value: 'Cpf', text: 'Pessoa Física' },
        { value: 'Cnpj', text: 'Pessoa Jurídica' },
        {},
      ],
      processo: [],
      buscaProcesso: [],
      municipios: [],
      profissoes: [],
      optionsEstadoCivil: [
        { value: "C", text: "Casado" },
        { value: "D", text: "Desquitado" },
        { value: "E", text: "União Estavel" },
        { value: "I", text: "Divorciado" },
        { value: "O", text: "Outros" },
        { value: "P", text: "Separado" },
        { value: "S", text: "Solteiro" },
        { value: "V", text: "Viúvo" }
      ],
      opcoesProfissao: [],
      opcoesNaturalidade: [],
      compradores: [],
      compradoresEnvio: [],
      cessionarios: [
        {
          nome: "",
          cpfCnpj: "",
          email: "",
          telefonePrincipal: "",
          telefoneRecado: "",
          estadoCivil: null,
          profissao: null,
          naturalidade: null,
          nomeConjuge: "",
          cpfCnpjConjuge: "",
          emailConjuge: "",
          telefonePrincipalConjuge: "",
          telefoneRecadoConjuge: "",
          estadoCivilConjuge: null,
          profissaoConjuge: null,
          naturalidadeConjuge: null,
          tipo: 'Principal'
        }
      ],
      representantes: [
        {
          nome: "",
          cpf: "",
          email: "",
          telefonePrincipal: "",
          telefoneRecado: "",
          estadoCivil: null,
          profissao: null,
          naturalidade: null,
          cessionario: null
        }
      ]
    }
  },
  methods: {
    async validateContrato() {
      try {

        this.buscaProcesso = await services.process.BuscarProcessoTransferencia(
          {
            "numVenda": this.selectedContract?.num_venda,
            "codColigada": this.selectedContract?.codcoligada,
            "contrato": this.selectedContract?.contrato
          }
        )

        this.loading = false

        if (this.buscaProcesso.data.length > 0) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Já existe um processo de transferência para o contrato selecionado. Solicitação: ${this.buscaProcesso.data[0]}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })

          return false

        } else {
          return true
        }

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Houve um problema para realizar a validação da etapa.",
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.loading = false
        return false
      }
    },
    validateRequisitos() {
      if (this.selectedContract?.inadimplente === 'Não' && this.selectedContract?.inadimplente_iptu === 'Não' && this.selectedContract?.situacao == '40') {
        return true
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "O contrato não preenche os requisitos para realizar a transferência.",
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return false
      }
    },
    async validateForm() {
      this.loading = true

      let validacaoProcessos = await this.validateContrato()
      let validacao = 0

      let cessionarios = []
      let representantes = []

      this.loading = true

      for (let index = 0; index < this.cessionarios.length; index++) {

        if (this.cessionarios[index].nome.length < 5 || !validarCPFCNPJ(this.cessionarios[index].cpfCnpj) || !validarEmail(this.cessionarios[index].email) || !validarTelefone(this.cessionarios[index].telefonePrincipal) || !validarTelefone(this.cessionarios[index].telefoneRecado) || (this.tipoComprador.value === 'Cpf' && (this.cessionarios[index].naturalidade == null || this.cessionarios[index].profissao == null || this.cessionarios[index].estadoCivil == null))) {
          validacao++
        } else {
          cessionarios.push({
            nome: this.cessionarios[index].nome,
            cpfCnpj: this.cessionarios[index].cpfCnpj,
            email: this.cessionarios[index].email,
            telefonePrincipal: this.cessionarios[index].telefonePrincipal,
            telefoneRecado: this.cessionarios[index].telefoneRecado,
            estadoCivil: (this.cessionarios[index].estadoCivil != null ? this.cessionarios[index].estadoCivil.value : null),
            profissao: (this.cessionarios[index].profissao != null ? this.cessionarios[index].profissao.codigo : null),
            naturalidade: (this.cessionarios[index].naturalidade != null ? this.cessionarios[index].naturalidade.descricao : null),
            CodMunicipio: (this.cessionarios[index].naturalidade != null ? this.cessionarios[index].naturalidade.codMunicipio : null),
            Uf: (this.cessionarios[index].naturalidade != null ? this.cessionarios[index].naturalidade.uf : null),
            observacao: this.cessionarios[index].nomeConjuge.length > 5 ?
              `
              nomeConjuge: ${this.cessionarios[index].nomeConjuge}
              cpfCnpjConjuge: ${this.cessionarios[index].cpfCnpjConjuge}
              emailConjuge: ${this.cessionarios[index].emailConjuge}
              telefonePrincipalConjuge: ${this.cessionarios[index].telefonePrincipal}
              telefoneRecadoConjuge: ${this.cessionarios[index].telefoneRecadoConjuge}
              estadoCivilConjuge: ${(this.cessionarios[index].estadoCivilConjuge != null ? this.cessionarios[index].estadoCivilConjuge.text : "")}
              profissaoConjuge: ${(this.cessionarios[index].profissaoConjuge != null ? this.cessionarios[index].profissaoConjuge.descricao : "")}
              naturalidadeConjuge: ${(this.cessionarios[index].naturalidadeConjuge != null ? this.cessionarios[index].naturalidadeConjuge.descricao : "")}` : ""
          })
        }

      }

      if (this.tipoComprador.value === 'Cnpj') {

        for (let index = 0; index < this.representantes.length; index++) {

          if (this.tipoComprador.value === 'Cnpj' && (this.representantes[index].nome.length < 5 || !validarCPFCNPJ(this.representantes[index].cpf) || !validarEmail(this.representantes[index].email) || !validarTelefone(this.representantes[index].telefonePrincipal) || !validarTelefone(this.representantes[index].telefoneRecado) || this.representantes[index].naturalidade == null || this.representantes[index].profissao == null || this.representantes[index].estadoCivil == null || (this.cessionarios.length > 1 && this.representantes[index].cessionario == null))) {
            validacao++
          } else {

            representantes.push({
              nome: this.representantes[index].nome,
              cpf: this.representantes[index].cpf,
              email: this.representantes[index].email,
              telefonePrincipal: this.representantes[index].telefonePrincipal,
              telefoneRecado: this.representantes[index].telefoneRecado,
              estadoCivil: this.representantes[index].estadoCivil.value,
              profissao: this.representantes[index].profissao.codigo,
              naturalidade: this.representantes[index].naturalidade.descricao,
              CodMunicipio: this.representantes[index].naturalidade.codMunicipio,
              Uf: this.representantes[index].naturalidade.uf,
              cessionario: this.cessionarios.length > 1 ? this.representantes[index].cessionario.nome : this.cessionarios[0].nome
            })

          }

        }
      }

      if (validacao == 0) {
        try {
          this.processo = await services.process.CriarProcessoTransferencia(
            {
              "numVenda": this.selectedContract?.num_venda,
              "codColigada": this.selectedContract?.codcoligada,
              "cessionarios": cessionarios,
              "Representates": representantes,
              "observacao": this.observacao,
              "compradores": this.compradoresEnvio
            }
          )

          this.numeroSolicitacao = this.processo.data[this.processo.data.length - 1][1]
          this.loading = false

          if (isNaN(this.numeroSolicitacao)) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Houve um problema para realizar a solicitação.",
                icon: 'EditIcon',
                variant: 'danger',
              },
            })

            return false
          }

          return true

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Houve um problema para realizar a solicitação.",
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          this.loading = false
          return false
        }

      } else {
        this.loading = false
        if (validacaoProcessos) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Preencha todos os campos.",
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
        return false
      }
    },
    validarCedentes() {
      let decisao = 0
      let compradores = []
      this.compradores.forEach(element => {
        if (!validarCPFCNPJ(element.chave_pix) && !validarTelefone(element.chave_pix) && !validarEmail(element.chave_pix)) {
          decisao++
          element.state = false
          element.mensagemErro = "A chave pix está vazia ou não atende os requisitos."
        } else {
          element.state = true

          compradores.push({
            compradorId: element.compradorId,
            nome: element.nome,
            telefone: element.telfone_recado,
            celular: element.telefone_principal,
            estadoCivil: element.estadoCivil.value,
            profissao: element.profissao.codigo,
            codMunicipioNaturalidade: (element.naturalidade ? element.naturalidade.codMunicipio : null),
            municipioNaturalidade: (element.naturalidade ? element.naturalidade.descricao : null),
            ufNaturalidade: (element.naturalidade ? element.naturalidade.uf : null),
            cnpjCpf: element.cpf,
            email: element.email,
            participacao: element.participacao,
            ChavePix: element.chave_pix
          })

        }

      })


      if (decisao > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "A chave pix é um campo obrigatório.",
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return false
      }

      this.compradoresEnvio = compradores

      return true
    },
    finalizarProcesso() {
      this.$toast({
        props: {
          title: 'Redirecionando para "Minhas Solicitações".',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.$router.push('/minhas-solicitacoes')
    },
  },
  async mounted() {
    try {

      this.profissoes = await services.process.ListaProfissoes()

      this.loading = false
      this.opcoesProfissao = this.profissoes.data

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Houve um problema para realizar a busca de profissões.",
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
      this.loading = false
    }

    try {
      this.municipios = await services.process.ListaMunicipios()

      this.loading = false
      this.opcoesNaturalidade = this.municipios.data

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Houve um problema para realizar a busca dos municipios.",
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
      this.loading = false
    }

    try {
      let dados = await services.process.ListaCompradores(this.selectedContract?.num_venda, this.selectedContract?.codcoligada)

      this.loading = false
      dados.data.forEach(element => {
        let profissao = this.opcoesProfissao.filter(opcao => opcao.codigo == element.profissao)
        let estadoCivil = this.optionsEstadoCivil.filter(opcao => opcao.value == element.estadoCivil)
        let naturalidade = (element.codMunicipioNaturalidade ? {
          codMunicipio: element.codMunicipioNaturalidade,
          descricao: element.municipioNaturalidade + '-' + element.ufNaturalidade,
          uf: element.ufNaturalidade
        } : null)

        this.compradores.push({
          compradorId: element.compradorId,
          naturalidade: naturalidade,
          profissao: profissao[0],
          estadoCivil: estadoCivil[0],
          nome: element.nome,
          telefone_principal: element.celular,
          telfone_recado: element.telefone,
          chave_pix: "",
          cpf: element.cnpjCpf,
          email: element.email,
          participacao: element.participacao,
          autenticacao: 'P',
          state: null,
          mensagemErro: null
        })
      })

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Houve um problema para realizar a busca dos municipios.",
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
      this.loading = false
    }

  },

  computed: {
    selectedContract() {
      return store.getters['contracts/getSelectedContract']
    },
    stateCpf() {
      return validarCPFCNPJ(this.cpfCessionario)
    },
    stateNome() {
      return this.nomeCessionario.length >= 5
    },
    stateTelefonePrincipal() {
      return validarTelefone(this.telefoneCessionario)
    },
    stateTelefoneRecado() {
      return validarTelefone(this.telefone2Cessionario)
    },
    stateEmail() {
      return validarEmail(this.emailCessionario)
    },
    stateProfissao() {
      return this.profissaoCessionario != null
    },
    stateNaturalidade() {
      return this.naturalidadeCessionario != null
    },
    stateEstadoCivil() {
      return this.estadoCivilCessionario != null
    },
    stateCpfConjuge() {
      if (this.cpfConjuge.length == 0) {
        return null
      } else {
        return validarCPFCNPJ(this.cpfConjuge)
      }
    },

    stateNomeConjuge() {
      if (this.nomeConjuge.length == 0) {
        return null
      } else {
        return this.nomeConjuge.length >= 5
      }
    },
    stateTelefonePrincipalConjuge() {
      if (this.telefoneConjuge.length == 0) {
        return null
      } else {
        return validarTelefone(this.telefoneConjuge)
      }
    },
    stateTelefoneRecadoConjuge() {
      if (this.telefone2Conjuge.length == 0) {
        return null
      } else {
        return validarTelefone(this.telefone2Conjuge)
      }
    },
    stateEmailConjuge() {
      if (this.emailConjuge.length == 0) {
        return null
      } else {
        return validarEmail(this.emailConjuge)
      }
    },
    stateProfissaoConjuge() {
      if (this.stateCpfConjuge != null || this.stateNomeConjuge != null || this.stateTelefonePrincipalConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateEmailConjuge != null) {
        return this.profissaoConjuge != null
      } else {
        return null
      }
    },
    stateNaturalidadeConjuge() {
      if (this.stateCpfConjuge != null || this.stateNomeConjuge != null || this.stateTelefonePrincipalConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateEmailConjuge != null) {
        return this.naturalidadeConjuge != null
      } else {
        return null
      }
    },
    stateEstadoCivilConjuge() {
      if (this.stateCpfConjuge != null || this.stateNomeConjuge != null || this.stateTelefonePrincipalConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateTelefoneRecadoConjuge != null || this.stateEmailConjuge != null) {
        return this.estadoCivilConjuge != null
      } else {
        return null
      }
    }
  },
}
</script>
