<template>
   <div>
      <b-row>
         <b-col cols="12" class="mb-2">
            <h3 class="mb-0 text-center">
               Solicitação de n.° {{ numeroSolicitacao }} foi realizada com Sucesso.
            </h3>
         </b-col>
      </b-row>

      <b-row>
         <b-col cols="12" class="mb-2">
            <p class="text-justify">
               Agora basta responder o e-mail recebido com os documentos necessários.
            </p>
            <p>
               Você pode acompanhar o status da sua solicitação através do menu <a href="/minhas-solicitacoes"><b class="redirecionamento" >Minhas Solicitações</b></a> ou clicando
               no botão abaixo.
            </p>
         </b-col>
      </b-row>

   </div>
</template>

<style>
.redirecionamento {
   cursor: pointer;
}

.redirecionamento:hover{
   text-decoration: underline;
}

</style>

<script>
import {
   BRow,
   BCol,
} from 'bootstrap-vue'
export default {
   props: {
      numeroSolicitacao: {
         type: String,
         required: true
      }
   },
   name: 'TabInicio',
   components: {
      BRow,
      BCol,
   },

   data() {
      return {
      }
   },
   methods: {
      finalizarProcesso() {
         this.$toast({
            props: {
               title: 'Redirecionando para "Minhas Solicitações".',
               icon: 'EditIcon',
               variant: 'success',
            },
         })
         this.$router.push('/minhas-solicitacoes')
      }
   },

   computed: {
   }
}
</script>
