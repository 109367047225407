module.exports = {
   validarCPFCNPJ(cpfCnpj) {

      if (cpfCnpj.length <= 14) {
         // Remove todos os caracteres não numéricos
         let cpf = cpfCnpj.replace(/\D/g, '')

         // Verifica se o CPF possui 11 dígitos
         if (cpf.length !== 11 ||
            // Verifica se todos os dígitos são iguais
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777"
            ||
            cpf === "88888888888" ||
            cpf === "99999999999") {
            return false
         }

         let soma = 0
         let
            resto

         // Calcula o primeiro dígito verificador
         for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
         }
         resto = (soma * 10) % 11
         if (resto === 10 || resto === 11) {
            resto = 0
         }
         if (resto
            !== parseInt(cpf.substring(9, 10))) {
            return false

         }

         // Calcula o segundo dígito verificador
         soma = 0
         for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
         }
         resto = (soma * 10) % 11
         if (resto === 10 || resto === 11) {
            resto = 0
         }
         if (resto !== parseInt(cpf.substring(10,
            11))) {
            return false
         }

         return true
      } else {
         // Remove todos os caracteres não numéricos
         let cnpj = cpfCnpj.replace(/[^\d]+/g, '')

         // Verifica se o CNPJ possui 14 dígitos
         if (cnpj == '') return false
         if (cnpj.length != 14) return false

         // Elimina CNPJs inválidos com todos os dígitos iguais
         if (/^(\d)\1{13}$/.test(cnpj)) return false

         // Validação dos dígitos verificadores
         let tamanho = cnpj.length - 2
         let numeros = cnpj.substring(0, tamanho)
         let digitos = cnpj.substring(tamanho)
         let soma
            = 0
         let pos = tamanho - 7

         for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2)
               pos = 9
         }

         let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
         if (resultado
            != digitos.charAt(0))
            return false

         tamanho = tamanho + 1
         numeros = cnpj.substring(0, tamanho)
         soma = 0
         pos = tamanho - 7

         for
            (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2)
               pos = 9
         }

         resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
         if (resultado != digitos.charAt(1))
            return false

         return true
      }

   },
   validarEmail(email) {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return regexEmail.test(email)
   },
   validarTelefone(telefone) {
      // Remove todos os caracteres não numéricos
      telefone = telefone.replace(/\D/g, '')

      // Verifica se o número possui 11 dígitos
      if (telefone.length !== 11) {
         return false
      }

      // Verifica se o DDD está entre 11 e 99
      const ddd = telefone.substring(0, 2)
      if (ddd < 11 || ddd > 99) {
         return false
      }

      return true
   }
}