<template>
   <div>
      <b-row v-for="(cessionario, index) in cessionarios" :key="cessionario.tipo">

         <b-col v-if="index > 0" md="12 direita">
            <b-button @click="removerLinha(index)" variant="danger" class="mr-1" style="padding: 5px">
               Remover <feather-icon size="15" icon="XIcon" />
            </b-button>
         </b-col>

         <b-col cols="12" class="mb-2">
            <h6 class="mb-0 text-left">
               Cessionario {{ cessionario.tipo }}
            </h6>
         </b-col>

         <b-col md="12">
            <b-form-group label="Nome *" label-for="nomeCessionario"
               :invalid-feedback="tipoComprador.value === 'Cpf' ? 'Digite o nome completo.' : 'Digite o nome da empresa.'"
               :state="cessionario.nome.length > 5">
               <b-form-input id="nomeCessionario" type="text" v-model="cessionario.nome" placeholder="Exemplo"
                  :state="cessionario.nome.length > 5" />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group :label="tipoComprador.value === 'Cpf' ? 'CPF *' : 'CNPJ *'" label-for="cpfCessionario"
               :invalid-feedback="tipoComprador.value === 'Cpf' ? 'CPF Incorreto.' : 'CNPJ Incorreto.'"
               :state="validacaoCpf(cessionario.cpfCnpj)">
               <b-form-input id="cpfCessionario" v-mask="tipoComprador.value === 'Cpf' ? maskCpf : maskCnpj" type="text"
                  v-model="cessionario.cpfCnpj" placeholder="000.000.000.00" :state="validacaoCpf(cessionario.cpfCnpj)"
                  trim />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Email *" label-for="emailCessionario" invalid-feedback="E-mail Invalido."
               :state="validacaoEmail(cessionario.email)">
               <b-form-input id="emailCessionario" type="email" v-model="cessionario.email"
                  placeholder="example.ex@email.com" :state="validacaoEmail(cessionario.email)" />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Principal*" label-for="telefoneCessionario"
               invalid-feedback="Telefone Invalido." :state="validacaoTelefone(cessionario.telefonePrincipal)">
               <b-form-input type="text" id="telefoneCessionario" v-mask="mask" v-model="cessionario.telefonePrincipal"
                  placeholder="(99) 99999-9999" :state="validacaoTelefone(cessionario.telefonePrincipal)" />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Recado*" label-for="telefone2Cessionario"
               invalid-feedback="Telefone Invalido." :state="validacaoTelefone(cessionario.telefoneRecado)">
               <b-form-input type="text" id="telefone2Cessionario" v-mask="mask" v-model="cessionario.telefoneRecado"
                  placeholder="(99) 99999-9999" :state="validacaoTelefone(cessionario.telefoneRecado)" />
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Estado Civil*" label-for="estadoCivil" invalid-feedback="Selecione um Estado Civil."
               :state="cessionario.estadoCivil != null">
               <v-select id="estadoCivil" :clearable="false" v-model="cessionario.estadoCivil"
                  :options="optionsEstadoCivil" @input="cessionario.estadoCivil"
                  :getOptionLabel="customerContracts => customerContracts.text" :searchable="true"
                  :state="cessionario.estadoCivil != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Profissão*" label-for="profissaoCessionario"
               invalid-feedback="Selecione uma Profissão." :state="cessionario.profissao != null">
               <v-select id="profissaoCessionario" :clearable="false" v-model="cessionario.profissao"
                  :options="opcoesProfissao" @input="cessionario.profissao"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true"
                  :state="cessionario.profissao != null" trim>
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Naturalidade*" label-for="naturalidadeCessionario"
               invalid-feedback="Selecione uma Profissão." :state="cessionario.naturalidade != null">
               <v-select id="naturalidadeCessionario" :clearable="false" v-model="cessionario.naturalidade"
                  :options="opcoesNaturalidade" @input="cessionario.naturalidade"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true"
                  :state="cessionario.naturalidade != null">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col cols="12" class="mb-2" v-if="tipoComprador.value === 'Cpf'">
            <h5 class="mb-0 text-left">
               Dados Cônjuge
            </h5>
         </b-col>

         <b-col md="12" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Nome do Cônjuge" label-for="nomeConjuge">
               <b-form-input id="nomeConjuge" type="text" v-model="cessionario.nomeConjuge" placeholder="Example" />
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="CPF do Cônjuge" label-for="cpfConjuge">
               <b-form-input id="cpfConjuge" v-mask="maskCpf" type="text" v-model="cessionario.cpfCnpjConjuge"
                  placeholder="000.000.000.00" />
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Email do Cônjuge" label-for="emailConjuge">
               <b-form-input id="emailConjuge" type="email" v-model="cessionario.emailConjuge" placeholder="example.ex@email.com" />
            </b-form-group>
         </b-col>

         <b-col md="3" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Telefone Principal" label-for="telefoneConjuge" >
               <b-form-input id="telefoneConjuge" v-mask="mask" v-model="cessionario.telefonePrincipalConjuge" placeholder="(99) 99999-9999"/>
            </b-form-group>
         </b-col>

         <b-col md="3" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Telefone Recado" label-for="telefone2Conjuge">
               <b-form-input id="telefone2Conjuge" v-mask="mask" v-model="cessionario.telefoneRecadoConjuge"
                  placeholder="(99) 99999-9999" />
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Estado Civil" label-for="estadoCivilConjuge">
               <v-select id="estadoCivilConjuge" :clearable="false" v-model="cessionario.estadoCivilConjuge"
                  :options="optionsEstadoCivil" @input="cessionario.estadoCivilConjuge"
                  :getOptionLabel="customerContracts => customerContracts.text" :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Profissão" label-for="profissaoConjuge">
               <v-select id="profissaoConjuge" :clearable="false" v-model="cessionario.profissaoConjuge" :options="opcoesProfissao"
                  @input="cessionario.profissaoConjuge" :getOptionLabel="customerContracts => customerContracts.descricao"
                  :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6" v-if="tipoComprador.value === 'Cpf'">
            <b-form-group label="Naturalidade" label-for="naturalidadeConjuge">
               <v-select id="naturalidadeConjuge" :clearable="false" v-model="cessionario.naturalidadeConjuge"
                  :options="opcoesNaturalidade" @input="cessionario.naturalidadeConjuge"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="12">
            <div class="divider"></div>
         </b-col>

      </b-row>

      <b-row>
         <b-col md="12 centro">
            <b-button @click="adicionarLinha" variant="primary" class="mr-1" style="padding: 5px">
               <feather-icon size="15" icon="PlusIcon" />
               Adicionar Cessionario
            </b-button>
         </b-col>
      </b-row>

   </div>
</template>

<style scoped>
.centro {
   display: flex;
   align-items: center;
   justify-content: center;
}

.direita {
   display: flex;
   align-items: right;
   justify-content: right;
}

.divider {
   background: #d0d2d6;
   width: 100%;
   height: 1px;
   margin-bottom: 2%;
   margin-top: 2%;
}
</style>

<script>
import vSelect from 'vue-select'
import {
   validarCPFCNPJ,
   validarEmail,
   validarTelefone
} from '@/utils/validations.js'
import {
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BButton,
} from 'bootstrap-vue'

export default {
   props: {
      cessionarios: {
         type: Array,
         required: true
      },
      opcoesNaturalidade: {
         type: Array,
         required: true
      },
      opcoesProfissao: {
         type: Array,
         required: true
      },
      optionsEstadoCivil: {
         type: Array,
         required: true
      },
      tipoComprador: {
         type: Object,
         required: true
      },
      optionsTipoComprador: {
         type: Array,
         required: true
      }
   },
   name: 'Cessionarios',
   components: {
      BRow,
      vSelect,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
   },

   data() {
      return {
         mask: ['(', /\d/, /\d/, ') ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
         maskCpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
         maskCnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      }
   },
   methods: {
      adicionarLinha() {
         this.cessionarios.push({
            nome: "",
            cpfCnpj: "",
            email: "",
            telefonePrincipal: "",
            telefoneRecado: "",
            estadoCivil: null,
            profissao: null,
            naturalidade: null,
            nomeConjuge: "",
            cpfCnpjConjuge: "",
            emailConjuge: "",
            telefonePrincipalConjuge: "",
            telefoneRecadoConjuge: "",
            estadoCivilConjuge: null,
            profissaoConjuge: null,
            naturalidadeConjuge: null,
            tipo: this.cessionarios.length + 1
         })
      },
      removerLinha(posicao) {
         this.cessionarios.splice(posicao, 1)
      },
      validacaoCpf(cpf) {
         return validarCPFCNPJ(cpf)
      },
      validacaoEmail(email) {
         return validarEmail(email)
      },
      validacaoTelefone(telefone) {
         return validarTelefone(telefone)
      }
   },

   computed: {
   }
}
</script>
