<template>
   <div>
      <b-row>
         <b-col cols="12" class="mb-2">
            <h3 class="mb-0 text-center">
               Cedente
            </h3>
            <h4 class="mb-0 text-center">
               (Proprietario Atual)
            </h4>
         </b-col>

      </b-row>
      <b-row v-for="comprador in compradores" :key="comprador.nome">

         <b-col md="12">
            <b-form-group label="Nome" label-for="nomeCedente">
               <b-form-input id="nomeCedente" type="text" :value="comprador.nome" readonly />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Principal*" label-for="telefoneCedente">
               <b-form-input type="text" id="telefoneCedente" :value="comprador.telefone_principal" v-mask="mask"
                  v-model="comprador.telefone_principal" placeholder="(99) 99999-9999" />
            </b-form-group>
         </b-col>

         <b-col md="3">
            <b-form-group label="Telefone Recado*" label-for="telefone2Cedente">
               <b-form-input type="text" id="telefone2Cedente" :value="comprador.telfone_recado" v-mask="mask"
                  v-model="comprador.telfone_recado" placeholder="(99) 99999-9999" />
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Estado Civil*" label-for="estadoCivilCedente">
               <v-select id="estadoCivilCedente" :clearable="false" v-model="comprador.estadoCivil"
                  :options="optionsEstadoCivil" @input="comprador.estadoCivil" :value="comprador.estadoCivil"
                  :getOptionLabel="customerContracts => customerContracts.text" :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Profissão" label-for="profissaoCedente">
               <v-select id="profissaoCedente" :clearable="false" v-model="comprador.profissao"
                  :options="opcoesProfissao" @input="comprador.profissao" :value="comprador.profissao"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="6">
            <b-form-group label="Naturalidade" label-for="naturalidadeCedente">
               <v-select id="naturalidadeCedente" :clearable="false" v-model="comprador.naturalidade"
                  :options="opcoesNaturalidade" @input="comprador.naturalidade" :value="comprador.naturalidade"
                  :getOptionLabel="customerContracts => customerContracts.descricao" :searchable="true">
                  <template #open-indicator="{ attributes }">
                     <feather-icon v-bind="attributes" icon="ChevronDownIcon" size="20" class="mr-1" />
                  </template>
               </v-select>
            </b-form-group>
         </b-col>

         <b-col md="12">
            <b-form-group label="Chave Pix para Autenticação" label-for="chavePix"
               description="Chave pix utilizada para realizar assinatura do documento, a chave obrigatóriamente deve ser apenas E-mail, Celular ou CPF."
               :invalid-feedback="comprador.mensagemErro" :state="comprador.chave_pix > 0">
               <b-form-input id="chavePix" type="text" v-model="comprador.chave_pix" :value="comprador.chave_pix"
                  :state="comprador.state" trim />
            </b-form-group>
         </b-col>

         <b-col md="12">
            <div class="divider"></div>
         </b-col>
      </b-row>
   </div>
</template>

<style scoped>
.centro {
   display: flex;
   align-items: center;
   justify-content: center;
}

.divider {
   background: #d0d2d6;
   width: 100%;
   height: 1px;
   margin-bottom: 2%;
   margin-top: 2%;
}
</style>

<script>
import vSelect from 'vue-select'
import {
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
} from 'bootstrap-vue'

export default {
   props: {
      compradores: {
         type: Array,
         required: true
      },
      opcoesNaturalidade: {
         type: Array,
         required: true
      },
      opcoesProfissao: {
         type: Array,
         required: true
      },
      optionsEstadoCivil: {
         type: Array,
         required: true
      }
   },
   name: 'Cedentes',
   components: {
      BRow,
      vSelect,
      BCol,
      BFormGroup,
      BFormInput,
   },

   data() {
      return {
         mask: ['(', /\d/, /\d/, ') ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
         maskCpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      }
   },
   methods: {

   },

   computed: {
   }
}
</script>
