<template>
	<div>
		<b-row>
			<b-col cols="12" class="mb-2">
				<h3 class="mb-0 text-center">
					Requisitos para a Transferência de Titularidade
				</h3>
			</b-col>
		</b-row>
		<div class="d-flex text-left my-1 align-items-center">
			<feather-icon :icon="returnIcon(isAdimplente).icon" size="19" :color="returnIcon(isAdimplente).color"
				class="mr-50" />
			<h6 class="requisito"> Estar adimplente com o financiamento.</h6>
		</div>

		<div class="d-flex text-left my-1 align-items-center">
			<feather-icon :icon="returnIcon(isIptuAdimplente).icon" size="19" :color="returnIcon(isIptuAdimplente).color"
				class="mr-50" />
			<h6 class="requisito">Não possuir débitos com o município.</h6>
		</div>

		<div class="d-flex text-left my-1 align-items-center">
			<feather-icon :icon="returnIcon(isLoteQuitado).icon" size="19" :color="returnIcon(isLoteQuitado).color"
				class="mr-50" />
			<h6 class="requisito">O Lote não pode estar quitado.</h6>
		</div>
	</div>
</template>
<style scoped>
.requisito {
	margin: 0;
	width: 90%;
}
</style>

<script>
import services from '@/services'
import store from '@/store'
import {
	BRow,
	BCol
} from 'bootstrap-vue'
export default {
	name: 'TabRequisitos',
	components: {
		BRow,
		BCol
	},

	data() {
		return {
			solicitacoes: [],
			loading: false,
		}
	},
	methods: {

		returnIcon(isAdimplente) {
			if (isAdimplente) {
				return {
					icon: 'CheckIcon',
					color: 'green',
				}
			} else {
				return {
					icon: 'XIcon',
					color: 'red',
				}
			}
		},

		async getSolicitacoes() {
			this.loading = true
			this.solicitacoes = await services.process.getProcesses(
				this.$apolloProvider.defaultClient
			)
			setTimeout(() => {
				this.loading = false
			}, 300)
		},
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},

		isAdimplente() {
			if (this.selectedContract?.inadimplente === 'Não') {
				return true
			} else {
				return false
			}
		},
		isIptuAdimplente() {
			if (this.selectedContract?.inadimplente_iptu === 'Não') {
				return true
			} else {
				return false
			}
		},
		isLoteQuitado() {
			if (this.selectedContract?.data_quitacao) {
				return false
			} else {
				return true
			}
		},
		quantosDiasJaQuitado() {
			if (this.selectedContract?.data_quitacao) {
				let dataQuitacao = new Date(
					this.selectedContract.data_quitacao.split('-')[0],
					this.selectedContract.data_quitacao.split('-')[1] - 1,
					this.selectedContract.data_quitacao.split('-')[2]
				)

				let dataHoje = new Date()

				let diferenca = dataHoje.getTime() - dataQuitacao.getTime()

				let dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))

				return dias
			} else {
				return 0
			}
		},

		maisDe60DiasNãoEscriturado() {
			if (!this.loteEscriturado && this.quantosDiasJaQuitado > 60) {
				return true
			}
			return false
		},

		preencheRequisitos() {
			if (
				this.isAdimplente &&
				this.isIptuAdimplente &&
				this.percentualPago &&
				this.liberadoConstrucao &&
				!this.maisDe60DiasNãoEscriturado &&
				!this.processoEmAndamento
			) {
				return true
			} else {
				return false
			}
		},
	},

	watch: {
		selectedContract: {
			handler: function () {
				this.selectedContract ? this.getSolicitacoes() : null
			},
		},
	},
}
</script>
